var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-container" }, [
      _c("p", [_vm._v("抱歉，您访问的页面不存在。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }